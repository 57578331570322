import { updateDataLayer } from '../../lib/datalayer';

function emitCheckboxEvent(item: { key: string }) {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: `thanks for contacting::${item.key}`
  });
}

export { emitCheckboxEvent };
