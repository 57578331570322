import React, { useEffect, useState } from 'react';
import withI18next from '../../i18n/TemplateI18n';
import { graphql } from 'gatsby';
import { GetContactData } from '../../storage/provider';
import {
  getCampusName,
  getCampusMeetup,
  getCampusFacebook,
} from '../../lib/campus';
import { emitCheckboxEvent } from './ga_events';
import { getLinkByPageName } from '../../lib/links';
import Deindex from '../../components/Deindex';
import Footer from '../../components/Footer';
import ThankYouTitle from '../../components/ThankYouTitle';
import CheckboxTable from '../../components/CheckboxTable';
import Layout from '../../components/atoms/layout';
import PropsThanksForApply from '../../interface/template';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

const ThanksForContact: React.SFC<any> = (props: PropsThanksForApply) => {
  const { t, pageContext } = props;
  const { pageName } = pageContext;
  const { userLocation } = useUserLocation();
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    course: '',
    campus: '',
  });

  useEffect(() => {
    setUserData(GetContactData());
  }, []);

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Deindex />
      <Layout>
        <ThankYouTitle
          title={t('contact-form-thanks:contactFormThanks.mainHeadline', {
            firstName: userData.name,
          })}
          subtitles={[
            t('contact-form-thanks:contactFormThanks.description.01', {
              email: userData.email,
            }),
            t('contact-form-thanks:contactFormThanks.description.02'),
          ]}
        />
        <CheckboxTable
          onSelect={emitCheckboxEvent}
          items={[
            {
              key: 'contacted',
              url: '',
              text: t(
                'contact-form-thanks:contactFormThanks.becomeIronhacker.list.01',
                { campus: getCampusName(userData.campus) }
              ),
            },
            {
              key: 'meetup',
              url: getCampusMeetup(userData.campus),
              text: t(
                'contact-form-thanks:contactFormThanks.becomeIronhacker.list.02'
              ),
            },
            {
              key: 'facebook',
              url: getCampusFacebook(userData.campus),
              text: t(
                'contact-form-thanks:contactFormThanks.becomeIronhacker.list.03'
              ),
            },
            {
              key: 'psg',
              url: getLinkByPageName('allCourses'),
              text: t(
                'contact-form-thanks:contactFormThanks.becomeIronhacker.list.04'
              ),
            },
          ]}
        />
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(contact-form-thanks)|(seo)|(breadcrumbs)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(ThanksForContact);
