import styled from 'styled-components';
import config from '../../components/config';
import { Component } from '../../components/atoms/body/text2/Component';

const ListContainer = styled.nav`
  box-shadow: 0 0.8rem 1.6rem ${config.palette.shadowGrey};
  border-radius: ${config.borderRadius};
  list-style: none;
  margin-bottom: 8rem;
  margin-top: 4.97rem;
  width: 100%;

  @media (max-width: ${config.resolutions.tablet}) {
    box-shadow: none;
    margin-bottom: 4rem;
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    width: calc(100% + 4.8rem);
  }
`;

const ListItem = styled(Component)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 3.2rem;
  padding-left: 6.4rem;

  .selected {
    display: none;
  }
  .not-selected {
    display: block;
  }

  &.completed {
    background-color: ${config.palette.skyBlue};
    color: white;

    .selected {
      display: block;
    }
    .not-selected {
      display: none;
    }
  }

  &:first-child {
    border-top-left-radius: ${config.borderRadius};
    border-top-right-radius: ${config.borderRadius};
  }

  &:last-child {
    border-bottom-left-radius: ${config.borderRadius};
    border-bottom-right-radius: ${config.borderRadius};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-left: 3.2rem;

    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }
`;

export { ListContainer, ListItem };
