import React, { useState } from 'react';
import classNames from 'classnames';
import { windowDefined } from '../../lib/utils';
import CheckboxItems, { CheckboxItem } from '../interfaces/CheckboxTableProps';
import { ListContainer, ListItem } from './style';
import CheckedCheckBox from '../../../static/assets/icons/checkbox/check-box-checked.svg';
import CheckBox from '../../../static/assets/icons/checkbox/check-box-black.svg';

interface State {
  key: string;
  selected: boolean;
}

function getDefaultState(items: CheckboxItem[]): State[] {
  return items.map((item: CheckboxItem, index: number) => ({
    key: item.key,
    selected: index === 0
  }));
}

function CheckboxTable(props: CheckboxItems) {
  const { items, onSelect = () => {} } = props;
  const [actions, setActions] = useState(getDefaultState(items));

  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    key: string
  ): void {
    e.preventDefault();
    const updatedActions = actions.map((action: State) => {
      if (action.key === key) {
        return { ...action, selected: true };
      }
      return action;
    });
    setActions(updatedActions);
    const currentAction = items.find((item: CheckboxItem) => item.key === key);
    if (windowDefined() && currentAction && currentAction.url !== '') {
      const windowName = currentAction.url.startsWith('http')
        ? '_blank'
        : '_self';
      window.open(currentAction.url, windowName);
    }
  }

  return (
    <ListContainer>
      {items.map((item: CheckboxItem) => {
        const currentAction = actions.find(
          (action: State) => action.key === item.key
        );
        const isSelected = currentAction && currentAction.selected;
        const itemClass = classNames({ completed: isSelected });
        return (
          <ListItem
            key={item.key}
            as="a"
            className={itemClass}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              onSelect(item);
              handleClick(e, item.key);
            }}
          >
            {item.text}
            <CheckedCheckBox className="selected" />
            <CheckBox className="not-selected" />
          </ListItem>
        );
      })}
    </ListContainer>
  );
}

export default CheckboxTable;
